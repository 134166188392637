import { StoresProvider } from "@stores/StoresProvider";
import { ReactQueryProvider } from "@libs/react-query/ReactQueryProvider";
import { config } from "@libs/config/config";
import { configure } from "mobx";
import { AppRoutes } from "modules/AppRoutes";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

import {
  BpThemeProvider,
  CenteredLargeSpinner,
  initializeIcons
} from "@bps/fluent-ui";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AuthenticationProvider } from "@bps/http-client";
import { ErrorAlert } from "@components/errors/ErrorAlert";
import { MainLayout } from "@layouts/MainLayout";

// observables can only be mutated within actions
configure({ enforceActions: "observed" });

// https://github.com/OfficeDev/office-ui-fabric-react/wiki/Using-icons
initializeIcons(
  "https://static2.sharepointonline.com/files/fabric/assets/icons/"
);

const redirectUri = `${window.location.origin}${config.msal.postAuthenticateRedirectUrl}`;

const loadAppInsights = () => {
  if (config.appInsights && !config.development) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [new ReactPlugin()]
      }
    });

    appInsights.loadAppInsights();
  }
};

export const App = () => {
  loadAppInsights();
  return (
    <BpThemeProvider styles={{ height: "100%" }}>
      <AuthenticationProvider
        config={{
          ...config.msal,
          redirectUri,
          postAuthenticateRedirectUrl: redirectUri
        }}
        renderSpinner={message => (
          <CenteredLargeSpinner label={message} labelPosition="bottom" />
        )}
        renderError={message => <ErrorAlert error={message} />}
      >
        <StoresProvider>
          <ReactQueryProvider>
            <Router basename={import.meta.env.PUBLIC_URL}>
              <MainLayout>
                <AppRoutes />
              </MainLayout>
            </Router>
            {config.development && (
              <ReactQueryDevtools position="bottom-left" />
            )}
          </ReactQueryProvider>
        </StoresProvider>
      </AuthenticationProvider>
    </BpThemeProvider>
  );
};
